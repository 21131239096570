import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/codebuild/output/src2579272825/src/kinetic-ui/docs/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "pagetitle"
    }}>{`PageTitle`}</h1>
    <p>{`Using this component on your page will allow you to alter the document title, as displayed in the
title bar or tab of the browser. It takes a list of parts, like breadcrumbs, and assembles them.`}</p>
    <h2 {...{
      "id": "props"
    }}>{`Props`}</h2>
    <pre><code parentName="pre" {...{}}>{`parts -> string[] = an array of strings to be assembled into a document title.
`}</code></pre>
    <h2 {...{
      "id": "example"
    }}>{`Example`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`import { PageTitle } from '@kineticdata/bundle-common';

<PageTitle parts={['first', 'second']} />
// The strings will be translated, if applicable. The result is "first | second | kinops"
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      